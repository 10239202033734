export const utils = {
  loadMore() { // 加载更多
    this.params.page++
    this.getListData()
  },
  // 表格区域
  handleSizeChange(val) { // 改变每页条数
    let _this = this
    // console.log(`第 ${this.searchForm.page} 页`)
    // console.log(`每页 ${val} 条`)

    _this.params.pageSize = val || 10
    _this.params.page = _this.params.page || 1

    this.getListData()
  },
  handleCurrentChange(val) { // 改变页码
    let _this = this
    // console.log(`第 ${val} 页`)
    // console.log(`每页 ${this.params.pageSize} 条`)
    _this.params.pageSize = _this.params.pageSize || 10
    _this.params.page = val || 1

    this.getListData()
  },
}